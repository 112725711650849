import Graphic from '@arcgis/core/Graphic';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AnnotationMode, SketchGeometryType } from 'helpers/quickLayerHelper';
import { QuickLayerTreeItem } from 'types/Layers/LibraryLayerTreeItem';
import { RootState } from './index';

interface QuickLayerSliceState {
    selectedAnnotation: Graphic | undefined;
    selectedQuickLayer: QuickLayerTreeItem | undefined;
    annotationMode: AnnotationMode;
    annotationPanel: boolean;
    highlightedAnnotationKey?: string;
    selectedGeometryType?: SketchGeometryType;
    isQuickLayerInCreateMode?: boolean;
}

const initialState: QuickLayerSliceState = {
    selectedAnnotation: undefined,
    selectedQuickLayer: undefined,
    annotationMode: 'view',
    annotationPanel: false,
    isQuickLayerInCreateMode: false,
};

export const quickLayerSlice = createSlice({
    name: 'quickLayer',
    initialState,
    reducers: {
        setSelectedAnnotation(state, action: PayloadAction<Graphic | undefined>) {
            state.selectedAnnotation = action.payload;
        },
        setSelectedQuickLayer(state, action: PayloadAction<QuickLayerTreeItem | undefined>) {
            state.selectedQuickLayer = action.payload;
        },
        setHighlightedAnnotationKey(state, action: PayloadAction<string | undefined>) {
            state.highlightedAnnotationKey = action.payload;
        },
        setAnnotationMode(state, action: PayloadAction<AnnotationMode>) {
            state.annotationMode = action.payload;
        },
        setGeometryType(state, action: PayloadAction<SketchGeometryType | undefined>) {
            state.selectedGeometryType = action.payload;
        },
        setIsQuickLayerInCreateMode(state, action: PayloadAction<boolean | undefined>) {
            state.isQuickLayerInCreateMode = action.payload;
        },
    },
});

export const selectedAnnotation = (state: RootState): Graphic | undefined => {
    return state.quickLayer?.selectedAnnotation;
};

export const selectedQuickLayer = (state: RootState): QuickLayerTreeItem | undefined =>
    state.quickLayer.selectedQuickLayer;

export const selectedAnnotationMode = (state: RootState): AnnotationMode => {
    return state.quickLayer.annotationMode;
};

export const selectHighlightedAnnotationKey = (state: RootState): string | undefined => {
    return state.quickLayer.highlightedAnnotationKey;
};

export const selectGeometryType = (state: RootState): SketchGeometryType | undefined => {
    return state.quickLayer.selectedGeometryType;
};

export const selectIsQuickLayerInCreateMode = (state: RootState): boolean | undefined => {
    return state.quickLayer.isQuickLayerInCreateMode;
};

export const {
    setSelectedAnnotation,
    setSelectedQuickLayer,
    setAnnotationMode,
    setHighlightedAnnotationKey,
    setGeometryType,
    setIsQuickLayerInCreateMode,
} = quickLayerSlice.actions;

export default quickLayerSlice.reducer;
