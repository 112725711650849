import { Button, Row, Space, Typography } from '@jll/react-ui-components';

import { exportPresentation } from 'api/presentationApi';

export default function ExportSlidesSection({ presentationId }: { presentationId: number }) {
    const handleExportPresentation = async (format: 'PDF' | 'Powerpoint') => {
        await exportPresentation(presentationId, format);
    };

    return (
        <>
            <Row>
                <Typography.Text
                    style={{
                        fontWeight: 'bold',
                        fontSize: '14px',
                        marginBottom: 5,
                    }}
                >
                    Export All Slides
                </Typography.Text>
            </Row>
            <Row>
                <Space>
                    <Button onClick={() => handleExportPresentation('Powerpoint')}>
                        Export As Powerpoint
                    </Button>
                    <Button onClick={() => handleExportPresentation('PDF')}>Export As PDF</Button>
                </Space>
            </Row>
        </>
    );
}
