import { Navigate, Route, Routes } from 'react-router-dom';
import { App as AntdApp } from '@jll/react-ui-components';
import App from 'App';
import Map from 'Map';

import AuthenticatedRoute from 'components/AuthenticatedRoute';
import AdminPage from 'pages/admin/AdminPage';
import ClientShareLink from 'pages/ClientShareLink';
import SignupPage from 'pages/signup/SignupPage';
import { ERROR_CONFIG, UnauthorizedPage } from 'pages/unauthorized/UnauthorizedPage';
import Welcome from 'pages/Welcome';
import ThemeProvider from 'themes/ThemeProvider';

const REDIRECT_PATHS = [
    'explore/na',
    'na',
    'explore/america',
    'america',
    'northamerica',
    'explore/us',
    'us',
    'explore/usa',
    'usa',
    'explore/canada',
    'canada',
    'explore/marketsphere',
    'marketsphere',
];

const AppWrapper = ({
    messageConfig,
}: {
    messageConfig: { duration: number; maxCount: number; top: number };
}) => (
    <ThemeProvider>
        <AntdApp message={messageConfig} style={{ height: '100%' }}>
            <App />
        </AntdApp>
    </ThemeProvider>
);

const AppRoutes = ({
    messageConfig,
}: {
    messageConfig: { duration: number; maxCount: number; top: number };
}) => {
    return (
        <Routes>
            <Route element={<AuthenticatedRoute allowClientUser />}>
                <Route element={<AppWrapper messageConfig={messageConfig} />}>
                    <Route path='presentation/:presentationId/*' element={<Map />} />
                    {Object.entries(ERROR_CONFIG).map(([errorKey, { name, messageRenderer }]) => (
                        <Route
                            key={errorKey}
                            path={`${name}/:presentationId`}
                            element={<UnauthorizedPage messageRenderer={messageRenderer} />}
                        />
                    ))}
                </Route>
            </Route>
            <Route element={<AuthenticatedRoute />}>
                <Route element={<AppWrapper messageConfig={messageConfig} />}>
                    <Route path='explore/global' element={<Map />} />
                    <Route
                        path='explore/northamerica'
                        element={<Map dataProvider='marketsphere' />}
                    />
                    {REDIRECT_PATHS.map((path) => (
                        <Route
                            key={path}
                            path={path}
                            element={<Navigate replace to='/explore/northamerica' />}
                        />
                    ))}
                    <Route path='*' element={<Welcome />} />
                    <Route path='admin' element={<AdminPage />} />
                </Route>
            </Route>
            <Route path='clientsharelink/:token' element={<ClientShareLink />} />
            <Route element={<AuthenticatedRoute allowClientUser />}>
                <Route path='signup' element={<SignupPage />} />
            </Route>

            <Route element={<AuthenticatedRoute />}>
                <Route element={<AppWrapper messageConfig={messageConfig} />}>
                    <Route path='explore/global' element={<Map />} />
                    <Route
                        path='explore/northamerica'
                        element={<Map dataProvider='marketsphere' />}
                    />
                    {REDIRECT_PATHS.map((path) => (
                        <Route
                            key={path}
                            path={path}
                            element={<Navigate replace to='/explore/northamerica' />}
                        />
                    ))}
                    <Route path='*' element={<Welcome />} />
                    <Route path='admin' element={<AdminPage />} />
                </Route>
            </Route>
        </Routes>
    );
};

export default AppRoutes;
