import { remove } from 'lodash';
import SortableJs from 'sortablejs';

import { ModifierType, Slide, SlideFolder, SlideItem } from 'components/Slides/SlideTypes';
import { getSignedUrl, uploadImage } from 'services/cloudinaryService';
import { nanoid } from 'utils/idUtils';

export function* createSlideItemIterator(items: SlideItem[]): IterableIterator<SlideItem> {
    for (const slideItem of items) {
        yield slideItem;
        if (slideItem.slideItemType === 'Folder') {
            yield* slideItem.children;
        }
    }
}

export function* createSlideIterator(items: SlideItem[]): IterableIterator<Slide> {
    for (const slideItem of items) {
        if (slideItem.slideItemType === 'Folder') {
            yield* slideItem.children;
        } else {
            yield slideItem;
        }
    }
}

export function* createSlideFolderIterator(items: SlideItem[]): IterableIterator<SlideFolder> {
    for (const slideItem of items) {
        if (slideItem.slideItemType === 'Folder') {
            yield slideItem;
        }
    }
}

export const getIndex = (list: SlideItem[], slideItem: SlideItem) => {
    return list.findIndex(
        (item) => item.slideItemType === slideItem.slideItemType && item.key === slideItem.key
    );
};

export const findSlideItem = (items: SlideItem[], callback: (slideItem: SlideItem) => boolean) => {
    let index = 0;
    for (const slideItem of createSlideItemIterator(items)) {
        if (callback(slideItem)) {
            return { slideItem, index };
        }

        index++;
    }

    return { index: -1 };
};

export const findFolder = (items: SlideItem[], callback: (folder: SlideFolder) => boolean) => {
    let index = 0;
    for (const folder of createSlideFolderIterator(items)) {
        if (callback(folder)) {
            return { folder, index };
        }

        index++;
    }

    return { index: -1 };
};

export const findSlide = (
    items: SlideItem[],
    callback: (slide: Slide) => boolean
): { slide?: Slide; index: number } => {
    let index = 0;
    for (const slide of createSlideIterator(items)) {
        if (callback(slide)) {
            return { slide, index };
        }

        index++;
    }

    return { index: -1 };
};

export const setActiveState = (activeSlide: Slide, slides: SlideItem[]) => {
    for (const slide of createSlideIterator(slides)) {
        if (slide.active === true) {
            slide.active = false;
            break;
        }
    }

    activeSlide.active = true;
};

export const findParentFolder = (slideItems: SlideItem[], slide: Slide) => {
    for (const folder of createSlideFolderIterator(slideItems)) {
        if (folder.children?.some((childSlide) => childSlide.key === slide.key)) return folder;
    }
};

export const getActiveSlide = (slides: SlideItem[]): { slide?: Slide; index: number } => {
    return findSlide(slides, (slide) => slide.active === true);
};

export const getAnalyticsCategory = (isClientUser: string) => {
    return isClientUser ? 'Client actions' : 'Slides';
};

export function getHiddenSlides(slideItems: SlideItem[]) {
    return [...createSlideIterator(slideItems)].filter((item) => item.hidden === true);
}

export function getSelectedSlideItems(slideItems: SlideItem[]) {
    return [...createSlideItemIterator(slideItems)].filter((item) => item.selected === true);
}

export function getSelectedSlides(slideItems: SlideItem[]) {
    return [...createSlideIterator(slideItems)].filter((item) => item.selected === true);
}

export function getSelectedFolders(slideItems: SlideItem[]) {
    return [...createSlideFolderIterator(slideItems)].filter((item) => item.selected === true);
}

export function deselectAllSlidesItems(slideItems: SlideItem[]) {
    for (const slideItem of createSlideItemIterator(slideItems)) {
        slideItem.selected = false;
    }
}

export const createEmptyFolder = () => {
    return {
        id: 0,
        slideItemType: 'Folder',
        key: nanoid(),
        expanded: false,
        children: [],
        name: '',
    } as SlideFolder;
};

export const createEmptySlide = () => {
    return { key: nanoid(), slideItemType: 'Slide' } as Slide;
};

export const addFolderWithSlides = (
    slideCollection: SlideItem[],
    slidesToInclude?: SlideItem[]
) => {
    const folder = createEmptyFolder();
    folder.expanded = true;
    const newCollection = [...slideCollection];
    folder.children = [];
    if (slidesToInclude) {
        const selectedSlides = slidesToInclude.filter(
            (item) => item.slideItemType === 'Slide'
        ) as Slide[];
        const firstSlideNotInFolder = selectedSlides.find(
            (slide) => !findParentFolder(newCollection, slide)
        );

        let destinationIndex = firstSlideNotInFolder
            ? getIndex(newCollection, firstSlideNotInFolder)
            : null;

        selectedSlides.forEach((slide: Slide) => {
            const parentFolder = findParentFolder(newCollection, slide);
            const parentCollection = parentFolder?.children ?? newCollection;
            parentCollection.splice(
                parentCollection.findIndex((childSlide) => childSlide.key === slide.key),
                1
            );

            folder.children.push(slide);

            // If the folder was completely emptied we will remove it from the list.
            if (parentFolder && parentFolder.children.length === 0) {
                const parentFolderIndex = newCollection.indexOf(parentFolder);
                newCollection.splice(parentFolderIndex, 1);

                // If there was no slide at the root or if the parent folder index is less than
                // the top most root slide, then use the removed folder as the location for the new folder.
                if (destinationIndex === null || parentFolderIndex < destinationIndex)
                    destinationIndex = parentFolderIndex;
            }
        });

        newCollection.splice(destinationIndex ?? newCollection.length, 0, folder);
    } else newCollection.push(folder);

    return { folder, newCollection };
};

export const cloneSlideItem = (slideItem: SlideItem) => {
    return slideItem.slideItemType === 'Folder'
        ? cloneSlideFolder(slideItem)
        : cloneSlide(slideItem);
};

export const cloneSlide = (slide: Slide) => {
    return { ...slide, id: 0, key: nanoid(), active: false } as Slide;
};

export const cloneSlideFolder = (slideFolder: SlideFolder) => {
    const copy = {
        ...slideFolder,
        id: 0,
        key: nanoid(),
        children: slideFolder.children.map((child) => {
            return cloneSlide(child);
        }),
    } as SlideFolder;

    return copy;
};

export const removeSlides = (slideCollection: SlideItem[], slidesToRemove: SlideItem[]) => {
    const selectedFolders = slidesToRemove.filter(
        (item) => item.slideItemType === 'Folder'
    ) as SlideFolder[];

    const selectedSlides = slidesToRemove.filter(
        (slideItem) => slideItem.slideItemType === 'Slide'
    ) as Slide[];

    selectedSlides.forEach((slide) => {
        const parentList = findParentFolder(slideCollection, slide)?.children ?? slideCollection;
        remove(parentList, (childSlide) => childSlide.key === slide.key);
    });

    selectedFolders.forEach((slideFolder) =>
        remove(slideCollection, (childSlide) => childSlide.key === slideFolder.key)
    );

    return slideCollection;
};

export const getSlideBackgroundColor = (hovered: boolean, selected?: boolean) => {
    if (hovered) return '#F5F6F6';
    else if (selected) return '#EEF2FD';

    return '#FFF';
};

export const getSlideFromSearchParams = (slides: SlideItem[], searchParams: URLSearchParams) => {
    const slideParam = searchParams.get('slide');
    if (!slideParam) return;
    const flattenedSlides = [...createSlideIterator(slides)];
    const index = parseInt(slideParam);
    return flattenedSlides[index - 1];
};

export const getModifierFromEvent = (e?: React.MouseEvent | React.KeyboardEvent) => {
    if (e?.shiftKey) return ModifierType.Shift;
    if (e?.ctrlKey) return ModifierType.Ctrl;
};

export const getClosestFolder = (e: SortableJs.MoveEvent) => {
    if (e.to.parentElement?.classList.contains('slide-list__slide-folder'))
        return e.to.parentElement;
};

export const keyboardControlsActive = (e: React.KeyboardEvent | React.MouseEvent | MouseEvent) => {
    return e.ctrlKey || e.metaKey || e.shiftKey;
};

/**
 * Uploads the image to cloudinary and returns the public id.
 * @param {Blob} imageBlob - The image to upload.
 * @returns {Promise<string>} - The public id of the image.
 */
export const uploadToCloudinary = async (imageBlob: Blob) => {
    const url = await getSignedUrl({
        folder: 'presentations/slides',
    });

    const cloudinaryResult = await uploadImage(url, imageBlob);
    return cloudinaryResult['public_id'];
};
