import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'store';

export enum ActionBarKeys {
    Default = 'default',
    Search = 'search',
    QuickLayer = 'quick_layer',
    Assistant = 'assistant',
}

export const ActionBarLabels = {
    [ActionBarKeys.Default]: 'Default',
    [ActionBarKeys.Search]: 'Search',
    [ActionBarKeys.QuickLayer]: 'Quick Layer',
    [ActionBarKeys.Assistant]: 'Assistant',
};

interface ActionBarItem {
    name: ActionBarKeys;
    hiddenInClientShare?: boolean;
}

const actionBars: {
    [key in ActionBarKeys]: ActionBarItem;
} = {
    [ActionBarKeys.Default]: {
        name: ActionBarKeys.Default,
        hiddenInClientShare: true,
    },
    [ActionBarKeys.Search]: {
        name: ActionBarKeys.Search,
    },
    [ActionBarKeys.QuickLayer]: {
        name: ActionBarKeys.QuickLayer,
        hiddenInClientShare: true,
    },
    [ActionBarKeys.Assistant]: {
        name: ActionBarKeys.Assistant,
        hiddenInClientShare: true,
    },
};

interface ActionBarState {
    activeActionBar: ActionBarItem | null;
}

const initialState: ActionBarState = {
    activeActionBar: actionBars[ActionBarKeys.Default],
};

export const actionBarSlice = createSlice({
    name: 'actionBar',
    initialState,
    reducers: {
        setActiveActionBar: (state, action: PayloadAction<ActionBarKeys>) => {
            const panel = actionBars[action.payload];
            state.activeActionBar = panel;
        },

        toggleActiveActionBar: (state, action: PayloadAction<ActionBarKeys>) => {
            const actionBar = actionBars[action.payload];
            state.activeActionBar =
                state.activeActionBar?.name === actionBar.name
                    ? actionBars[ActionBarKeys.Default]
                    : actionBar;
        },

        clearActionBar: (state) => {
            state.activeActionBar = actionBars[ActionBarKeys.Default];
        },
    },
});

export const { setActiveActionBar, clearActionBar, toggleActiveActionBar } = actionBarSlice.actions;

export const selectActiveActionBar = (state: RootState): ActionBarItem | null =>
    state.actionBar.activeActionBar;

export const getActiveActionBarKey = (state: RootState): ActionBarKeys | null =>
    state.actionBar.activeActionBar?.name ?? null;

export default actionBarSlice.reducer;
