import { useCallback } from 'react';
import { useLazyQuery } from '@apollo/client';
import { App, MenuItemType } from '@jll/react-ui-components';
import { uniqBy } from 'lodash';

import HighlightSetMenuItem from 'components/mapContextMenu/HighlightSetMenuItem';
import { getLibraryItemBounds } from 'helpers/libraryLayerHelper';
import {
    buildingMetadataForItemKey,
    createOsmChildren,
    createOsmHighlightLibraryItem,
    getBuildingIdFromNode,
    getGeometryRefFromNode,
    getHighlightedBuilding,
    getOsmBuildingsFromGraphics,
    isUnmatchedEntry,
    recreateHighlightSetChildren,
} from 'helpers/osmHighlightSetHelper';
import { useMap } from 'hooks/MapProvider';
import { GET_PROPERTY_ID, PropertyGraphQLResult } from 'services/graphql/csp';
import {
    enterMatchMode,
    selectOsmHighlightInMatchMode,
    startMatch,
    viewMatch,
} from 'store/csvHighlightMatchSlice';
import { selectHighlightSetProperties, setHighlightSetProperties } from 'store/highlightSetSlice';
import { renameAction } from 'store/libraryItemActionsSlice';
import {
    addLibraryLayerTreeItem,
    removeCheckedKeys,
    removeChildrenFromLayerItem,
    removeLibraryLayerTreeItemByKey,
    selectCheckedKeys,
    selectLibraryItemsByKey,
    selectOsmHighlightSetLibraryItems,
    setLibraryItemsChecked,
    startEditMode,
    updateCheckedKeys,
    updateLibraryItem,
} from 'store/libraryItemSlice';
import { selectedGraphics } from 'store/polygonsLayerSlice';
import { RightPanelKeys, setActiveRightPanel } from 'store/rightPanelSlice';
import { GoToTarget3DOptions } from 'types/GoToTarget3DOptions';
import { useAppDispatch, useAppSelector } from 'types/hooks';
import {
    buildingGeometryRefEqual,
    buildingGeometryRefFromGraphic,
    BuildingGeometryRefSet,
} from 'types/Layers/BuildingGeometryRef';
import { ContextMenuItem, MenuItem } from 'types/Layers/ContextMenu';
import LibraryLayerTreeItem, {
    OsmHighlightSetTreeItem,
    primaryLibraryItemKey,
} from 'types/Layers/LibraryLayerTreeItem';
import {
    OsmHighlightSetBuilding,
    OsmHighlightSetMetadata,
    treeItemKeyForBuilding,
} from 'types/Layers/OsmHighlightSetMetadata';
import { filteredMenuItems } from 'utils/filteredMenuItems';
import isDefined from 'utils/isDefined';
import { useGetMarketSpherePropertyIdByRef } from './buildingSelectionStyleHelpers';

export const useHighlightSetActions = () => {
    const { modal } = App.useApp();
    const dispatch = useAppDispatch();
    const itemsById = useAppSelector(selectLibraryItemsByKey);
    const checkedKeys = useAppSelector<string[]>(selectCheckedKeys);
    const currentGraphics = useAppSelector(selectedGraphics);
    const osmHighlightSets = useAppSelector(selectOsmHighlightSetLibraryItems);
    const highlightSetProperties = useAppSelector(selectHighlightSetProperties);
    const osmHighlightInMatchingModeKey = useAppSelector(selectOsmHighlightInMatchMode);
    const getMarketSpherePropertyIdByRef = useGetMarketSpherePropertyIdByRef();
    const { hideMapContextMenu, setGoTo } = useMap();
    const [getPropertyData] = useLazyQuery<PropertyGraphQLResult>(GET_PROPERTY_ID, {
        context: {
            clientName: 'inquiryServiceEndpoint',
        },
    });

    const addBuildingToHighlightSet = (
        libraryItem: OsmHighlightSetTreeItem,
        buildings: OsmHighlightSetBuilding[],
        address?: string
    ): OsmHighlightSetTreeItem => {
        const { key } = libraryItem;
        const metadata = libraryItem.metadata as OsmHighlightSetMetadata;
        let children = createOsmChildren(key, buildings, metadata);
        if (address) {
            children = children.map((child) => ({
                ...child,
                title: address,
            }));
        }

        const libraryItemChildren = uniqBy([...(libraryItem.children ?? []), ...children], 'key');

        const updatedLibraryItem = {
            ...libraryItem,
            children: libraryItemChildren,
            metadata: {
                ...metadata,
                buildings: uniqBy([...metadata.buildings, ...buildings], 'id'),
            },
        };
        dispatch(
            updateLibraryItem({
                libraryItem: updatedLibraryItem,
                key,
            })
        );
        dispatch(
            setLibraryItemsChecked(
                libraryItemChildren.filter((child) => child.checked).map((item) => item.key)
            )
        );
        return updatedLibraryItem;
    };

    const removeBuildingsFromOSMHighlightSet = (
        highlightSetRef: OsmHighlightSetTreeItem,
        buildings: OsmHighlightSetBuilding[]
    ) => {
        const removeGeometryRefs = new BuildingGeometryRefSet(
            buildings.map((building) => building.geometryRef).filter(isDefined)
        );
        const metadata = highlightSetRef.metadata as OsmHighlightSetMetadata;
        const removeBuildings = metadata.buildings.filter(
            (building) =>
                isDefined(building.geometryRef) && removeGeometryRefs.has(building.geometryRef)
        );
        const removeBuildingsKeys = removeBuildings.map((building) =>
            treeItemKeyForBuilding(highlightSetRef.key, building)
        );
        const keepChildren = highlightSetRef.children?.filter(
            (child) => !removeBuildingsKeys.includes(child.key)
        );
        const keepBuildings = metadata.buildings.filter(
            (building) => !removeBuildings.includes(building)
        );
        const updatedMetadata: OsmHighlightSetMetadata = {
            ...metadata,
            buildings: keepBuildings,
        };
        const libraryItem = {
            ...highlightSetRef,
            children: keepChildren,
            metadata: updatedMetadata,
        };
        dispatch(
            updateLibraryItem({
                libraryItem: libraryItem,
                key: libraryItem.key,
            })
        );
        dispatch(removeCheckedKeys(removeBuildingsKeys));

        return libraryItem;
    };

    const updateHighlightSetOrder = (
        layer: OsmHighlightSetTreeItem,
        childKey: string,
        newIndex: number
    ): OsmHighlightSetTreeItem | undefined => {
        if (layer.children) {
            const metadata = layer.metadata as OsmHighlightSetMetadata;
            const childIndex = layer.children.findIndex((child) => child.key === childKey);
            if (childIndex >= 0) {
                const updatedChildren = [...layer.children];
                const [child] = updatedChildren.splice(childIndex, 1);
                updatedChildren.splice(newIndex, 0, child);
                const updatedBuildingIds = updatedChildren.map((item) =>
                    getBuildingIdFromNode(item)
                );
                const buildingsById = new Map<string | undefined, OsmHighlightSetBuilding>();
                metadata.buildings.forEach((building) => {
                    buildingsById.set(building.id ?? `unmatched--${building.extraId}`, building);
                });
                const updatedBuildings = updatedBuildingIds.reduce<OsmHighlightSetBuilding[]>(
                    (acc, buildingId) => {
                        const building = buildingsById.get(buildingId);
                        if (building) {
                            acc.push(building);
                        }
                        return acc;
                    },
                    []
                );

                const children = recreateHighlightSetChildren(
                    layer.key,
                    updatedBuildings,
                    metadata
                );

                return {
                    ...layer,
                    children: children,
                    metadata: {
                        ...metadata,
                        buildings: updatedBuildings,
                    },
                };
            }
        }
    };

    const removeBuildingFromHighlightSet = (libraryItem: LibraryLayerTreeItem) => {
        const buildingId = getBuildingIdFromNode(libraryItem);
        const parentKey = primaryLibraryItemKey(libraryItem.key);
        const parentHighlightSet = osmHighlightSets.find((item) => item.key === parentKey);
        let keys = checkedKeys.filter((key) => key !== libraryItem.key);
        if (parentHighlightSet) {
            const { key, children, metadata } = parentHighlightSet;
            const styleProperties = metadata as OsmHighlightSetMetadata;
            if (children?.length) {
                if (children.filter((item) => !item.key.includes('preview-table')).length == 1) {
                    dispatch(removeLibraryLayerTreeItemByKey(parentKey));
                    keys = keys.filter((key) => key !== parentKey);
                } else {
                    const buildings = styleProperties.buildings.filter(
                        (building) => building.id !== buildingId
                    );
                    const updatedChildren = recreateHighlightSetChildren(
                        key,
                        buildings,
                        styleProperties
                    );
                    dispatch(
                        updateLibraryItem({
                            libraryItem: {
                                ...parentHighlightSet,
                                children: updatedChildren,
                                metadata: { ...styleProperties, buildings },
                            },
                            key,
                        })
                    );
                    dispatch(removeChildrenFromLayerItem(libraryItem));
                }
            }
        }
        dispatch(updateCheckedKeys(keys));
    };

    const handleMatchWithBuilding = (libraryItemKey: string) => {
        dispatch(startMatch(libraryItemKey));
        dispatch(setActiveRightPanel(RightPanelKeys.TableToHighlightMatch));
    };

    const getHighlightSetMenuItems = (
        libraryItem: OsmHighlightSetTreeItem,
        primaryOsmHighlightItem: OsmHighlightSetTreeItem | undefined
    ): MenuItemType[] => {
        const { isLeaf, key, legendEnabled } = libraryItem;
        if (isLeaf) {
            const metadata = primaryOsmHighlightItem?.metadata;
            const buildingMetadata =
                metadata && buildingMetadataForItemKey(libraryItem.key, metadata);
            return filteredMenuItems([
                {
                    key: 'renameProperty',
                    label: 'Rename',
                    onClick: () => dispatch(renameAction(key)),
                },
                {
                    key: 'removeProperty',
                    label: 'Remove property',
                    onClick: () => removeBuildingFromHighlightSet(libraryItem),
                },
                {
                    key: 'matchWithBuilding',
                    label: 'Match with building',
                    onClick: () => handleMatchWithBuilding(libraryItem.key),
                    visible: buildingMetadata && isUnmatchedEntry(buildingMetadata),
                },
                {
                    key: 'review-match',
                    label: 'Review Match',
                    onClick: async () => {
                        if (!isDefined(buildingMetadata?.geometryRef)) {
                            return;
                        }

                        dispatch(viewMatch(libraryItem.key));
                        dispatch(setActiveRightPanel(RightPanelKeys.TableToHighlightMatch));
                        await goToHighlightSetTreeItem(libraryItem);
                    },
                    visible:
                        isDefined(buildingMetadata?.extraId) &&
                        isDefined(buildingMetadata.geometryRef),
                },
            ]);
        }

        return filteredMenuItems([
            {
                key: 'changeHighlightSetStyling',
                label: 'Change styling',
                onClick: () => {
                    dispatch(startEditMode({ libraryItem }));
                    dispatch(
                        setHighlightSetProperties(libraryItem.metadata as OsmHighlightSetMetadata)
                    );
                    dispatch(setActiveRightPanel(RightPanelKeys.HighlightSet));
                },
                visible: libraryItem.key === primaryOsmHighlightItem?.key,
            },
            {
                key: 'toggleLegend',
                label: legendEnabled ? 'Remove from legend' : 'Add to legend',
                onClick: () => toggleLegend(libraryItem),
                visible: libraryItem.key === primaryOsmHighlightItem?.key,
            },
            {
                key: 'enterMatchingMode',
                label: 'Enter matching mode',
                onClick: () => {
                    if (primaryOsmHighlightItem) {
                        dispatch(
                            enterMatchMode({ osmHighlightSetKey: primaryOsmHighlightItem.key })
                        );
                        dispatch(setActiveRightPanel(RightPanelKeys.TableToHighlightMatch));
                    }
                },
                visible:
                    osmHighlightInMatchingModeKey !== primaryOsmHighlightItem?.key &&
                    primaryOsmHighlightItem?.key === libraryItem.key &&
                    primaryOsmHighlightItem?.metadata?.buildings.some(
                        (building) => building.extraId
                    ),
            },
        ]);
    };

    const handleFetchPropertyData = async (propertyId: string): Promise<string | undefined> => {
        try {
            const { data } = await getPropertyData({
                variables: {
                    propertyId,
                    source: 'MarketSphere',
                },
            });

            const name = data?.getProperty?.name;
            const addressLine1 = data?.getProperty?.address?.[0]?.address?.line1;

            return name ?? addressLine1;
        } catch (error) {
            console.error('Error fetching property data:', error);
        }
    };

    const createNewHighlightSet = async (highlightSetTitle = 'Highlight Set') => {
        const osmBuildings = getOsmBuildingsFromGraphics(currentGraphics);
        const geometryRef = buildingGeometryRefFromGraphic(currentGraphics[0]);
        if (geometryRef) {
            const marketSpherePropertyId = await getMarketSpherePropertyIdByRef(geometryRef);
            if (marketSpherePropertyId) {
                osmBuildings[0].name = await handleFetchPropertyData(
                    marketSpherePropertyId.toString()
                );
            }
        }

        const libraryItem = createOsmHighlightLibraryItem(
            osmBuildings,
            highlightSetProperties,
            highlightSetTitle
        );
        const checkedKeys = [...(libraryItem.children?.map((item) => item.key) ?? [])];
        dispatch(addLibraryLayerTreeItem(libraryItem));
        dispatch(setLibraryItemsChecked(checkedKeys));
        dispatch(startEditMode({ libraryItem, created: true }));
        dispatch(setActiveRightPanel(RightPanelKeys.HighlightSet));
    };

    const handleHighlightOsmBuilding = async (libraryItem: OsmHighlightSetTreeItem) => {
        const geometryRef = buildingGeometryRefFromGraphic(currentGraphics[0]);
        let address: string | undefined = '';
        if (geometryRef) {
            const marketSpherePropertyId = await getMarketSpherePropertyIdByRef(geometryRef);
            if (marketSpherePropertyId) {
                address = await handleFetchPropertyData(marketSpherePropertyId.toString());
            }
        }

        const highlightedBuilding = geometryRef && getHighlightedBuilding(libraryItem, geometryRef);
        if (highlightedBuilding) {
            removeBuildingFromHighlightSet(highlightedBuilding);
        } else {
            addBuildingToHighlightSet(
                libraryItem,
                getOsmBuildingsFromGraphics(currentGraphics),
                address
            );
        }
    };

    const handleOnHighlightSetClick = (highlightSet: OsmHighlightSetTreeItem) => {
        hideMapContextMenu();
        handleHighlightOsmBuilding(highlightSet);
    };

    const getMapContextMenuItems = (): MenuItem[] => {
        const subMenuItems = osmHighlightSets
            .filter(({ metadata }) => !metadata?.csvLayerMetadata)
            .map((libraryItem) => {
                const { key, title, metadata } = libraryItem;
                const geometryRef = buildingGeometryRefFromGraphic(currentGraphics[0]);
                const isHighlighted =
                    geometryRef && getHighlightedBuilding(libraryItem, geometryRef);
                return ContextMenuItem({
                    label: (
                        <HighlightSetMenuItem
                            title={title}
                            color={(metadata as OsmHighlightSetMetadata)?.pinColor}
                            checked={!!isHighlighted}
                        />
                    ),
                    key: key,
                    onClick: () => {
                        handleOnHighlightSetClick(libraryItem);
                    },
                });
            });
        const highlightSetMenu = [
            ...(subMenuItems.length > 0
                ? [
                      ContextMenuItem({
                          label: 'Add to Existing',
                          children: subMenuItems,
                      }),
                      ContextMenuItem({
                          type: 'divider',
                      }),
                  ]
                : []),

            ContextMenuItem({
                label: 'New Highlight Set',
                key: 'newHighlight',
                onClick: () => {
                    hideMapContextMenu();
                    createNewHighlightSet();
                },
            }),
        ];

        return [
            ContextMenuItem({
                label: 'Highlight Building',
                key: 'highlightBuilding',
                children: highlightSetMenu,
            }),
        ];
    };

    const toggleLegend = (libraryItem: LibraryLayerTreeItem) => {
        const { key, legendEnabled } = libraryItem;
        dispatch(
            updateLibraryItem({
                libraryItem: {
                    legendEnabled: !legendEnabled,
                },
                key,
            })
        );
    };

    const revertHighlightSetActions = (
        originalHighlightItem: OsmHighlightSetTreeItem,
        editedLibraryItem?: OsmHighlightSetTreeItem,
        created?: boolean
    ) => {
        const createdKeys: string[] = [];
        const removedKeys: string[] = [];
        const originalChildren = originalHighlightItem.children ?? [];
        if (created) {
            dispatch(removeLibraryLayerTreeItemByKey(originalHighlightItem.key));
            createdKeys.push(
                originalHighlightItem.key,
                ...originalChildren.map((item) => item.key)
            );
        } else {
            if (!editedLibraryItem || !editedLibraryItem.children) return createdKeys;
            const buildings = new BuildingGeometryRefSet(
                originalChildren
                    .map((item) => getGeometryRefFromNode(originalHighlightItem.metadata, item))
                    .filter(isDefined)
            );

            const editedBuildings = new BuildingGeometryRefSet(
                (editedLibraryItem.metadata as OsmHighlightSetMetadata).buildings
                    .map((building) => building.geometryRef)
                    .filter(isDefined)
            );
            dispatch(
                updateLibraryItem({
                    key: originalHighlightItem.key,
                    libraryItem: originalHighlightItem,
                })
            );
            const lastAddedKeys = editedLibraryItem.children
                .filter(
                    (item) =>
                        !buildings.has(getGeometryRefFromNode(originalHighlightItem.metadata, item))
                )
                .map((item) => item.key);
            const lastRemovedKeys = originalChildren
                .filter(
                    (item) =>
                        !editedBuildings.has(
                            getGeometryRefFromNode(originalHighlightItem.metadata, item)
                        )
                )
                .map((item) => item.key);
            createdKeys.push(...lastAddedKeys);
            removedKeys.push(...lastRemovedKeys);
        }
        dispatch(removeCheckedKeys(createdKeys));
        dispatch(setLibraryItemsChecked(removedKeys));
    };

    const transferHighlightSet = (
        dragItem: OsmHighlightSetTreeItem,
        dragParent: OsmHighlightSetTreeItem,
        dropNode: OsmHighlightSetTreeItem,
        dropIndex: number
    ) => {
        const geometryRef = getGeometryRefFromNode(dragParent.metadata, dragItem);
        const metadata = dragParent.metadata as OsmHighlightSetMetadata;
        const removeBuilding = metadata.buildings.find((item) =>
            buildingGeometryRefEqual(item.geometryRef, geometryRef)
        );
        if (!removeBuilding) return;
        removeBuildingFromHighlightSet(dragItem);
        const updatedDropNode = addBuildingToHighlightSet(dropNode, [removeBuilding]);
        const updatedChild = updatedDropNode.children?.find(
            (item) => getBuildingIdFromNode(item) == removeBuilding.id
        );
        if (!updatedChild) return;
        const updatedDropOrder = updateHighlightSetOrder(
            updatedDropNode,
            updatedChild.key,
            dropIndex
        );
        if (!updatedDropOrder) return;
        dispatch(
            updateLibraryItem({
                libraryItem: updatedDropOrder,
                key: dropNode.key,
            })
        );
    };

    const onHandleCancel = async (isDirty: boolean, onCancel: () => void) => {
        if (isDirty) {
            return await modal.confirm({
                title: 'Are you sure you want to cancel?',
                content: 'All unsaved changes will be lost.',
                onOk: onCancel,
            });
        } else {
            return await onCancel();
        }
    };

    const getParentItem = useCallback(
        (key: string) => {
            const parentKey = primaryLibraryItemKey(key);
            return osmHighlightSets.find((highlightSet) => highlightSet.key === parentKey);
        },
        [osmHighlightSets]
    );

    const goToHighlightSetTreeItem = async (libraryItem: OsmHighlightSetTreeItem) => {
        const primaryItem = libraryItem.key
            ? itemsById[primaryLibraryItemKey(libraryItem.key)]
            : undefined;
        const bounds = await getLibraryItemBounds(libraryItem, primaryItem);
        if (bounds) setGoTo(bounds as GoToTarget3DOptions);
    };

    return {
        removeBuildingFromHighlightSet,
        addBuildingToHighlightSet,
        removeBuildingsFromOSMHighlightSet,
        getHighlightSetMenuItems,
        getMapContextMenuItems,
        createNewHighlightSet,
        toggleLegend,
        transferHighlightSet,
        updateHighlightSetOrder,
        revertHighlightSetActions,
        onHandleCancel,
        getParentItem,
        handleMatchWithBuilding,
        goToHighlightSetTreeItem,
    };
};
