/* eslint-disable @typescript-eslint/no-explicit-any */
import Color from '@arcgis/core/Color';
import Geometry from '@arcgis/core/geometry/Geometry';
import * as geometryEngine from '@arcgis/core/geometry/geometryEngine';
import Point from '@arcgis/core/geometry/Point';
import Polygon from '@arcgis/core/geometry/Polygon';
import Polyline from '@arcgis/core/geometry/Polyline';
import SpatialReference from '@arcgis/core/geometry/SpatialReference';
import Graphic from '@arcgis/core/Graphic';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';
import GroupLayer from '@arcgis/core/layers/GroupLayer';
import LabelClass from '@arcgis/core/layers/support/LabelClass';
import UniqueValueInfo from '@arcgis/core/renderers/support/UniqueValueInfo';
import UniqueValueRenderer from '@arcgis/core/renderers/UniqueValueRenderer';
import LineSymbol3D from '@arcgis/core/symbols/LineSymbol3D';
import LineSymbol3DLayer from '@arcgis/core/symbols/LineSymbol3DLayer';
import PointSymbol3D from '@arcgis/core/symbols/PointSymbol3D';
import PolygonSymbol3D from '@arcgis/core/symbols/PolygonSymbol3D';
import TextSymbol from '@arcgis/core/symbols/TextSymbol';
import TextSymbol3DLayer from '@arcgis/core/symbols/TextSymbol3DLayer';
import FeatureLayerView from '@arcgis/core/views/layers/FeatureLayerView';
import iconUrl from 'icons/custom/pin.svg?url';
import { compact, get, isEmpty, isEqual, isNil, merge, uniq } from 'lodash';

import { LayerListItemModel } from 'components/layerWindow/LayerListItem';
import ExtendedGraphic from 'types/esri/ExtendedGraphics';
import LibraryLayerTreeItem, { QuickLayerTreeItem } from 'types/Layers/LibraryLayerTreeItem';
import {
    AnnotationAttributes,
    AnnotationMetadata,
    AnnotationStyleProperties,
    QuickLayerContentDto,
    QuickLayerDto,
    QuickLayerMetadata,
} from 'types/Layers/QuickLayerMetadata';
import endpoints from 'utils/apiClient/endpoints';
import { findMapLayer } from 'utils/esri/findMapLayerUtils';
import { getLayerView } from 'utils/esri/layerViewUtils';
import {
    buildNumberedIcon,
    createIconForMaterialSymbols,
    getPinSizeForDisplay,
    renderNumberedIcon,
} from 'utils/iconBuilder/iconBuilderUtils';
import { nanoid } from 'utils/idUtils';

export const getDefaultAnnotationStyles = (): AnnotationStyleProperties => {
    return {
        color: 'rgba(133,85,136,0.8)',
        outlineColor: 'rgba(133,85,136,1)',
        icon: 'location_on',
        iconType: 'generic',
        size: 40,
        opacity: 0.8,
        outlineWidth: 2,
        polylineWidth: 3,
        outlineOpacity: 1,
        outlineEnabled: true,
        lineStyle: 'solid',
        labelingEnabled: false,
        labelSize: 13,
        labelHalo: false,
    };
};

export const DEFAULT_ANNOTATION_STATE: AnnotationState = {
    attributes: {
        title: '',
        description: '',
        images: '',
        documents: '',
    },
    style: getDefaultAnnotationStyles(),
};

export const annotationHighlightOptions = {
    haloColor: new Color('#FFA500'),
    color: new Color('#40798D'),
};

export const quickLayerButtonKeySuffix = 'add-a-shape';

export type AnnotationMode = 'edit' | 'view';

export type AnnotationState = {
    attributes: AnnotationAttributes;
    style: AnnotationStyleProperties;
};

export interface FetchLayerResult {
    results: LayerListItemModel[];
    total: number;
}

export interface QuickLayerSearchPayload {
    searchQuery: string;
    offset: number;
    limit: number;
}

export const defaultPointSymbol = (): __esri.PointSymbol3DProperties => {
    const { color, size } = getDefaultAnnotationStyles();
    return new PointSymbol3D({
        symbolLayers: [
            {
                type: 'icon',
                resource: {
                    href: iconUrl,
                },
                size: `${size}px`,
            },
        ],
        verticalOffset: {
            screenLength: 50,
            maxWorldLength: 3000,
            minWorldLength: 50,
        },
        callout: {
            type: 'line',
            color: color,
            size: 0.5,
            border: {
                color: color,
            },
        },
    });
};

export const defaultPolygonSymbol = (): __esri.PolygonSymbol3DProperties => {
    const { color, outlineWidth } = getDefaultAnnotationStyles();
    return new PolygonSymbol3D({
        symbolLayers: [
            {
                type: 'fill',
                material: {
                    color,
                },
                outline: {
                    color,
                    size: outlineWidth,
                },
            },
        ],
    });
};

export const defaultPolylineSymbol = (): __esri.LineSymbol3DProperties => {
    const { color, polylineWidth } = getDefaultAnnotationStyles();
    return new LineSymbol3D({
        symbolLayers: [
            new LineSymbol3DLayer({
                material: { color },
                size: polylineWidth,
            }),
        ],
    });
};

export const createQuickLayer = async (title: string): Promise<QuickLayerTreeItem> => {
    const itemType = 'QuickLayer';
    const layerId = await saveQuickLayer({ name: title });
    const metadata: QuickLayerMetadata = {
        quickLayerId: layerId,
    };
    const key = `${itemType}--${nanoid()}`;
    return {
        id: layerId,
        key,
        title,
        itemType,
        active: true,
        checked: true,
        metadata,
        legendEnabled: false,
    };
};

export const createSketchOptions = (
    style = getDefaultAnnotationStyles()
): __esri.SketchViewModelProperties => {
    const { color, size, opacity, polylineWidth, outlineWidth } = style;
    const alphaColor = new Color(color);
    alphaColor.a = opacity;
    return {
        pointSymbol: {
            type: 'point-3d',
            symbolLayers: [
                {
                    type: 'icon',
                    resource: {
                        href: iconUrl,
                    },
                    size: `${size}px`,
                },
            ],
        },
        polylineSymbol: {
            type: 'line-3d',
            symbolLayers: [
                new LineSymbol3DLayer({
                    material: { color },
                    size: polylineWidth,
                }),
            ],
        },
        polygonSymbol: {
            type: 'polygon-3d',
            symbolLayers: [
                {
                    type: 'fill',
                    material: {
                        color: alphaColor,
                    },
                    outline: {
                        color: color,
                        size: outlineWidth,
                    },
                },
            ],
        },
        defaultCreateOptions: { hasZ: false },
    };
};

export const createGraphicStyles = async (
    graphic: Graphic,
    style = getDefaultAnnotationStyles()
) => {
    const type = graphic.geometry.type;
    const symbol = await createSymbol(type, style);
    if (!symbol) return;

    graphic.set('symbol', symbol);
    return graphic;
};

export const createSymbol = (type: string, style: AnnotationStyleProperties) => {
    const {
        color,
        icon,
        iconType,
        size,
        opacity,
        polylineWidth,
        outlineWidth,
        outlineColor,
        lineStyle,
        outlineEnabled,
    } = style;
    const alphaColor = new Color(color);
    alphaColor.a = opacity;
    const pattern = {
        type: 'style',
        style: lineStyle,
    } as __esri.LineStylePattern3DProperties;
    switch (type) {
        case 'point':
            return createPointSymbol(icon, iconType, size, alphaColor.toString());
        case 'polygon':
            return createPolygonSymbol(
                alphaColor,
                outlineWidth,
                outlineEnabled,
                outlineColor,
                pattern
            );
        case 'polyline':
            return createPolylineSymbol(alphaColor, polylineWidth, pattern);
        default:
            return null;
    }
};

export const createPointIcon = (icon: string, iconType: string, size: number, color: string) => {
    const foregroundColor = '#FFF';
    if (iconType === 'generic')
        return createIconForMaterialSymbols(icon, foregroundColor, size, color);
    else if (iconType === 'numbered') {
        const numberedIcon = renderNumberedIcon(
            {
                type: 'numbered',
                numberingType: 'arabic',
                name: 'Numbered',
                font: 'Source Sans Pro',
            },
            1,
            8
        );
        return buildNumberedIcon(
            numberedIcon,
            color,
            foregroundColor,
            getPinSizeForDisplay('numbered', size)
        );
    }
};

export const createPointSymbol = async (
    icon: string,
    iconType: string,
    size: number,
    color: Color | string
) => {
    const pointIcon = await createPointIcon(icon, iconType, size, color.toString());
    if (pointIcon) {
        const iconUrl = pointIcon instanceof HTMLCanvasElement ? pointIcon.toDataURL() : pointIcon;
        return new PointSymbol3D({
            symbolLayers: [
                {
                    type: 'icon',
                    resource: {
                        href: iconUrl,
                    },
                    size: `${size}px`,
                },
            ],
        });
    }
};

export const createPolygonSymbol = (
    color: Color | string,
    size: number,
    outlineEnabled: boolean,
    outlineColor?: Color | string,
    lineStyle?: __esri.LineStylePattern3DProperties
) => {
    if (!outlineColor) outlineColor = color;
    const outline = outlineEnabled
        ? { color: outlineColor, size, pattern: lineStyle, type: 'simple-line' }
        : undefined;
    return new PolygonSymbol3D({
        symbolLayers: [
            {
                type: 'fill',
                material: {
                    color,
                },
                outline: outline,
            },
        ],
    });
};

export const createPolylineSymbol = (
    color: Color | string,
    size: number,
    lineStyle: __esri.LineStylePattern3DProperties
) => {
    return new LineSymbol3D({
        symbolLayers: [
            new LineSymbol3DLayer({
                material: { color },
                size,
                pattern: lineStyle,
            }),
        ],
    });
};

const transformGraphicSymbol = (graphic: Graphic) => {
    const symbol = JSON.parse(JSON.stringify(graphic.symbol));

    symbol.type = graphic.symbol.type;

    if (symbol.symbolLayers) {
        symbol.symbolLayers.forEach((layer: any) => {
            layer.type = layer.type.toLowerCase();

            if (layer.material?.color) {
                const transparency = layer.material.transparency || 0;
                layer.material.color.push((100 - transparency) / 100);
            }

            if (layer.resource?.dataURI) {
                layer.resource.href = layer.resource.dataURI;
                delete layer.resource.dataURI;
            }
        });
    }

    return symbol;
};

export const transformGraphic = (graphic: Graphic) => {
    const { attributes } = graphic;
    const geometry = merge({}, graphic.geometry);
    const symbol = transformGraphicSymbol(graphic);
    return {
        key: get(graphic, 'key'),
        geometry,
        symbol,
        attributes,
    };
};

export const saveQuickLayer = async (collection: QuickLayerDto): Promise<number> => {
    const saveResponse = await endpoints.quickLayer.create.post({
        fetchOptions: {
            body: JSON.stringify(collection),
        },
    });

    if (!saveResponse.ok) {
        throw new Error('Failed to save annotation collection');
    }
    const { id } = await saveResponse.json();
    return id;
};

export const updateQuickLayer = async (collectionContent: QuickLayerContentDto): Promise<void> => {
    try {
        await endpoints.quickLayer.update.put({
            fetchOptions: {
                body: JSON.stringify(collectionContent),
            },
        });
    } catch (e) {
        throw new Error('Failed to save annotation collection');
    }
};

export const getQuickLayerSignedUrl = async (id: number): Promise<string> => {
    const response = await endpoints.quickLayer.signedUrl.get({
        templateValues: {
            id,
        },
    });

    return await response.text();
};

export const getGalleryLayerSignedUrl = async (id: number, revisionId: string): Promise<string> => {
    const response = await endpoints.quickLayer.gallerySignedUrl.get({
        queryParameters: {
            id,
            revisionId,
        },
    });
    return await response.text();
};

export const downloadGraphics = async (id: number) => {
    try {
        const url = await getQuickLayerSignedUrl(id);
        const response = await fetch(url);
        return await response.json();
    } catch (error) {
        throw new Error('Failed to download quick layer content');
    }
};

export const downloadGalleryLayerGraphics = async (id: number, revisionId: string) => {
    try {
        const url = await getGalleryLayerSignedUrl(id, revisionId);
        const response = await fetch(url);
        return await response.json();
    } catch (error) {
        throw new Error('Failed to download quick layer content');
    }
};

//To support old annotation collection
export const transformAnnotationKeyToQuickLayer = (key: string) => {
    const [collectionType, ...rest] = key.split('--');

    if (collectionType === 'AnnotationCollection') {
        key = `QuickLayer--${rest.join('--')}`;
    }
    return key;
};

export const createQuickLayerSaveState = (libraryItem: QuickLayerTreeItem) => {
    const { quickLayerId, isGalleryItem, revisionId } = libraryItem.metadata as QuickLayerMetadata;
    const items = [] as QuickLayerTreeItem[];
    const children = (libraryItem.children as QuickLayerTreeItem[]) ?? [];
    children.forEach((item) => {
        const { title, checked, isActionItem } = item;
        const key = transformAnnotationKeyToQuickLayer(item.key);
        items.push({
            key,
            title,
            checked,
            isActionItem,
        } as QuickLayerTreeItem);
    });
    return {
        children: items,
        active: items.filter((item) => !item.isActionItem && item.checked).length > 0,
        quickLayerId,
        isGalleryItem,
        revisionId,
    };
};

const checkIfChildIsActionItem = (child: QuickLayerTreeItem) => {
    return child.key.includes(quickLayerButtonKeySuffix) || child.isActionItem;
};

export const createQuickLayerChildren = (
    parentKey: string,
    graphics: AnnotationMetadata[]
): QuickLayerTreeItem[] => {
    return graphics.map((annotation: AnnotationMetadata) => {
        const { attributes } = annotation;
        const transformedParentKey = transformAnnotationKeyToQuickLayer(parentKey);
        const postfix = annotation.key?.split('--')[2];
        const childId =
            postfix && !postfix.includes(quickLayerButtonKeySuffix) ? postfix : nanoid();
        const key = `${transformedParentKey}--${childId}`;
        annotation.key = key;

        return {
            id: 0,
            key,
            title: attributes.title,
            itemType: 'QuickLayer',
            checked: true,
            isLeaf: true,
            metadata: annotation,
            ownerId: transformedParentKey,
        } as QuickLayerTreeItem;
    });
};

export const createQuickLayerFromSavedState = (layer: any): LibraryLayerTreeItem => {
    const itemType = 'QuickLayer';
    const { id, guid, name, active, quickLayerId, isGalleryItem, revisionId } = layer;
    const children = (layer.children ?? []) as QuickLayerTreeItem[];
    const key = guid ?? `${itemType}--${nanoid()}`;
    const transformedChildren = children.map(
        (child) =>
            ({
                id: 0,
                key: checkIfChildIsActionItem(child) ? `${key}--${nanoid()}` : child.key,
                title: child.title,
                checked: child.checked,
                itemType,
                isLeaf: true,
                metadata: child.metadata,
                ownerId: key,
                legendEnabled: false,
                isActionItem: checkIfChildIsActionItem(child),
            } as QuickLayerTreeItem)
    );

    return {
        id: 0,
        key,
        title: name,
        itemType,
        children: transformedChildren,
        checked: !isNil(active) ? active : true,
        metadata: { quickLayerId: quickLayerId ?? id, isGalleryItem, revisionId },
    };
};

export const fetchQuickLayers = async (
    payload: QuickLayerSearchPayload
): Promise<FetchLayerResult> => {
    const response = await endpoints.quickLayer.search.get({
        queryParameters: { ...payload },
    });
    return await response.json();
};

export const searchQuickLayerById = (libraryItems: LibraryLayerTreeItem[], layerId: number) => {
    return libraryItems.some((item) => {
        return (
            item.itemType === 'QuickLayer' &&
            item.metadata &&
            'quickLayerId' in item.metadata &&
            item.metadata.quickLayerId === layerId
        );
    });
};

export const getQuickLayerBounds = async (libraryItem: LibraryLayerTreeItem) => {
    const layerId = libraryItem.ownerId ?? libraryItem.key;
    const groupLayer = findMapLayer(layerId) as GroupLayer;

    if (!groupLayer) {
        throw new Error('Group layer not found.');
    }

    const whereClause = libraryItem.ownerId ? `key = '${libraryItem.key}'` : '1=1';

    let graphics = [] as Graphic[];

    await Promise.all(
        groupLayer.layers.map(async (layer) => {
            if (layer instanceof FeatureLayer) {
                const result = await layer.queryFeatures({
                    where: whereClause,
                    returnGeometry: true,
                });
                if (result?.features) {
                    graphics = [...graphics, ...result.features];
                }
            }
        })
    );

    return graphics;
};

export const findSelectedGraphicStyle = (
    selectedGraphic: Graphic | undefined,
    quickLayer: LibraryLayerTreeItem | undefined
) => {
    if (!selectedGraphic || !quickLayer) return;
    const key = get(selectedGraphic, 'key') ?? selectedGraphic.getAttribute?.('key');
    if (quickLayer.children?.length) {
        const child = quickLayer.children.find((child) => child.key == key) as QuickLayerTreeItem;
        return (child?.metadata as AnnotationMetadata)?.style;
    }
};

export const createFeatureLayers = (key: string) => {
    const pointLayer = createFeatureLayer(key, 'point', defaultPointSymbol(), 'relative-to-scene');
    const polygonLayer = createFeatureLayer(
        key,
        'polygon',
        defaultPolygonSymbol(),
        'on-the-ground'
    );
    const polylineLayer = createFeatureLayer(
        key,
        'polyline',
        defaultPolylineSymbol(),
        'on-the-ground'
    );
    return [pointLayer, polygonLayer, polylineLayer];
};

export type SketchGeometryType = 'point' | 'polygon' | 'polyline' | 'circle';

const symbolGeometryType = (geometryType: SketchGeometryType) => {
    switch (geometryType) {
        case 'point':
            return defaultPointSymbol();
        case 'polyline':
            return defaultPolylineSymbol();
        case 'circle':
        case 'polygon':
            return defaultPolygonSymbol();
    }
};

export const filterEmptyGraphicChildren = (
    quickLayerItem: QuickLayerTreeItem
): QuickLayerTreeItem => {
    const { children } = quickLayerItem;
    if (!children?.length) return quickLayerItem;

    const updatedChildren = children.filter((item) => {
        const metadata = (item as QuickLayerTreeItem).metadata as AnnotationMetadata;
        if (!metadata) return item;
        const { geometry } = metadata;
        if (geometry) {
            const emptyGeometry = createEmptyGeometry(geometry.type as SketchGeometryType);
            return !isEqual(emptyGeometry, geometry);
        }
    });
    return {
        ...quickLayerItem,
        children: updatedChildren,
    };
};

const createEmptyGeometry = (geometryType: SketchGeometryType): Geometry => {
    return {
        type: geometryType == 'circle' ? 'polygon' : geometryType,
        spatialReference: SpatialReference.WGS84,
    } as Geometry;
};

export const getQuickLayerDefaultTitle = (geometryType: SketchGeometryType, refNum = 1) => {
    return geometryType.charAt(0).toUpperCase() + geometryType.slice(1) + ' ' + refNum;
};
export const createEmptyAnnotationMetadata = (
    geometryType: SketchGeometryType,
    key: string,
    refNum = 1
): AnnotationMetadata => {
    const geometry = createEmptyGeometry(geometryType);
    const title = getQuickLayerDefaultTitle(geometryType, refNum);
    return {
        attributes: {
            title: title,
            description: '',
            images: '',
            documents: '',
        },
        style: getDefaultAnnotationStyles(),
        key,
        geometry,
        symbol: symbolGeometryType(geometryType),
    } as AnnotationMetadata;
};

export const isQuickLayerButton = (libraryItem: LibraryLayerTreeItem) => {
    return libraryItem.itemType === 'QuickLayer' && libraryItem.isActionItem;
};

export const createFeatureLayer = (
    id: string,
    geometryType: SketchGeometryType,
    symbol:
        | __esri.PointSymbol3DProperties
        | __esri.PolygonSymbol3DProperties
        | __esri.LineSymbol3DProperties,
    elevationMode?: any
) => {
    const labelClass = createLabelClass();
    return new FeatureLayer({
        id: `${id}--${geometryType}`,
        geometryType: geometryType == 'circle' ? 'polygon' : geometryType,
        elevationInfo: {
            mode: elevationMode,
        },
        spatialReference: SpatialReference.WGS84,
        renderer: new UniqueValueRenderer({
            field: 'key',
            defaultSymbol: symbol,
            uniqueValueInfos: [] as UniqueValueInfo[],
        }),
        popupEnabled: false,
        legendEnabled: true,
        labelingInfo: [labelClass],
        labelsVisible: true,
        source: [],
        outFields: ['key'],
        fields: [
            {
                name: 'ObjectID',
                type: 'oid',
            },
            {
                name: 'key',
                type: 'string',
            },
            {
                name: 'title',
                type: 'string',
            },
            {
                name: 'description',
                type: 'string',
            },
            {
                name: 'images',
                type: 'string',
            },
            {
                name: 'documents',
                type: 'string',
            },
        ],
    });
};

export const highlightAnnotation = async (
    graphic: Graphic,
    annotationLayer: FeatureLayer
): Promise<IHandle> => {
    const layerView = (await getLayerView(annotationLayer)) as FeatureLayerView;
    return layerView.highlight(graphic);
};

function getPolylineMidPoint(polyline: Polyline): Point {
    const center = polyline.extent.center;
    const { coordinate } = geometryEngine.nearestCoordinate(polyline, center);
    return coordinate;
}

export const createLabelGraphic = (title: string, geometry: Geometry, key: string) => {
    const textSymbol = new TextSymbol({
        text: title,
        color: '#333333', // Hex color code
        font: {
            size: 13,
            family: 'Arial',
        },
        haloSize: 0,
        haloColor: 'transparent',
        horizontalAlignment: 'center',
        verticalAlignment: 'baseline',
    });
    let point;
    if (geometry.type == 'point') {
        point = geometry as Point;
    } else if (geometry.type == 'polyline') {
        point = getPolylineMidPoint(geometry as Polyline);
    } else if (geometry.type == 'polygon') {
        point = (geometry as Polygon).centroid;
    }
    const graphic = new Graphic({
        geometry: point,
        symbol: textSymbol,
    });
    graphic.set('key', key);
    return graphic;
};

export const applyLabelSettings = (children: QuickLayerTreeItem[], featureLayer: FeatureLayer) => {
    const labels = children?.map((child) => {
        if (!child.metadata || 'addShape' in child.metadata) return;
        const metadata = child.metadata as AnnotationMetadata;
        const { labelingEnabled, labelHalo, labelSize } = metadata.style;
        const where = `key = '${child.key}'`;
        if (labelingEnabled) return createLabelClass(where, labelSize, labelHalo);
    });
    const labelClasses = compact(uniq(labels));
    if (!labelClasses.length) {
        featureLayer.set('labelingInfo', []);
        return;
    }
    featureLayer.set('labelingInfo', labelClasses);
};

export const createLabelClass = (where?: string, size = 13, labelHalo = false) => {
    const haloOptions = labelHalo ? { haloSize: 2, haloColor: 'white' } : { haloSize: 0 };
    const textSymbol = new TextSymbol({
        color: '#333333',
        font: {
            size,
            family: 'Arial',
        },
        horizontalAlignment: 'center',
        verticalAlignment: 'baseline',
        ...haloOptions,
    });

    return new LabelClass({
        where,
        symbol: textSymbol,
        labelExpressionInfo: {
            expression: '$feature.title',
        },
    });
};

const createFeaturesLookup = async (layerId: string) => {
    const featuresByType: Record<string, Graphic[]> = {
        point: [],
        polygon: [],
        polyline: [],
    };

    const groupLayer = findMapLayer(layerId) as GroupLayer;
    if (!groupLayer) {
        throw new Error('Group layer not found.');
    }
    await Promise.all(
        groupLayer.layers.map(async (layer) => {
            const featureLayer = layer as FeatureLayer;
            const result = await featureLayer.queryFeatures({ where: '1=1', returnGeometry: true });
            featuresByType[featureLayer.geometryType] = result.features;
        })
    );
    return featuresByType;
};

export const findLayerFromGroup = (key: string, geometryType: string) => {
    const groupLayer = findMapLayer(key) as GroupLayer;
    const featureLayer = groupLayer.findLayerById(`${key}--${geometryType}`) as FeatureLayer;
    return featureLayer;
};

const applyEditsToFeatureLayer = async (
    key: string,
    selectedGraphic: ExtendedGraphic,
    state: AnnotationState
) => {
    const selectedGraphicKey = get(selectedGraphic, 'key')?.toString();
    const { geometry, symbol } = selectedGraphic;
    const addFeatures = [];
    const deleteFeatures = [];

    const featureLayer = findLayerFromGroup(key, geometry.type);
    if (!featureLayer) return;

    const result = await featureLayer.queryFeatures({ where: '1=1', returnGeometry: true });
    if (!result) {
        return;
    }

    const images = state.attributes.images;
    const documents = state.attributes.documents;

    const newFeature = new Graphic({
        geometry,
        symbol,
        attributes: {
            ...state.attributes,
            images,
            documents,
            key: selectedGraphicKey,
        },
    });
    newFeature.set('key', selectedGraphicKey);
    addFeatures.push(newFeature);

    const existingFeature = result.features.find(
        (feature) => feature.getAttribute?.('key') == selectedGraphicKey
    );

    if (existingFeature) {
        deleteFeatures.push(existingFeature);
    }

    await featureLayer.applyEdits({
        addFeatures,
        deleteFeatures,
    });

    const renderer = featureLayer.renderer as UniqueValueRenderer;
    const uniqueValueInfo = {
        value: selectedGraphicKey,
        symbol: symbol,
    } as UniqueValueInfo;

    const filtered = renderer.uniqueValueInfos.filter((info) => info.value !== selectedGraphicKey);

    renderer.uniqueValueInfos = [...filtered, uniqueValueInfo];
};

export const saveQuickLayerContent = async (
    key: string,
    quickLayerId: number,
    children: LibraryLayerTreeItem[],
    state: AnnotationState,
    title?: string
) => {
    const featuresByType = await createFeaturesLookup(key);
    const updatedAnnotations = children
        .map((child) => {
            const { key, metadata, ownerId: parentKey } = child as QuickLayerTreeItem;

            if (!parentKey) {
                return null;
            }

            const annotationMetadata = metadata as AnnotationMetadata;
            const attributes = annotationMetadata?.attributes;
            const geometryType = annotationMetadata?.geometry?.type;

            const features = featuresByType[geometryType];
            const graphic = features?.find(
                (feature) => get(feature, 'key') || feature.getAttribute?.('key') === key
            );

            if (!graphic) {
                return null;
            }

            const featureLayer = findLayerFromGroup(parentKey, geometryType);
            const layerRenderer = featureLayer.renderer as UniqueValueRenderer;

            graphic.set(
                'symbol',
                layerRenderer.uniqueValueInfos.find((info) => info.value === key)?.symbol
            );
            graphic.set('attributes', {
                ...graphic.attributes,
                ...attributes,
                key,
            });

            const transformedGraphic = transformGraphic(graphic);
            const style = annotationMetadata?.style || state.style;

            return {
                ...transformedGraphic,
                style,
                key,
            } as AnnotationMetadata;
        })
        .filter((annotation): annotation is AnnotationMetadata => annotation !== null);

    const content = JSON.stringify(updatedAnnotations);

    await updateQuickLayer({ id: quickLayerId, content, ...(title && { name: title }) });
};

export const saveAnnotation = async (
    selectedGraphic: Graphic,
    quickLayer: QuickLayerTreeItem,
    children: LibraryLayerTreeItem[],
    state: AnnotationState
) => {
    if (selectedGraphic && quickLayer) {
        const { key, metadata } = quickLayer;
        const quickLayerId = (metadata as QuickLayerMetadata).quickLayerId;

        await applyEditsToFeatureLayer(key, selectedGraphic, state);

        await saveQuickLayerContent(key, quickLayerId, children, state);

        if (selectedGraphic.layer instanceof GraphicsLayer) {
            selectedGraphic.layer.remove(selectedGraphic);
        }
    }
};

export const createLabelPoint = (
    graphic: ExtendedGraphic,
    key: string,
    style?: AnnotationStyleProperties
) => {
    style =
        style ??
        (get(graphic, 'style') as AnnotationStyleProperties) ??
        getDefaultAnnotationStyles();

    let title = graphic.getAttribute?.('title');
    title = isEmpty(title) ? 'No title' : title;
    const geometry =
        graphic.geometry.type == 'point' ? graphic.geometry : graphic.geometry.extent.center;
    const labelPoint = new Graphic({
        geometry: geometry,
    });
    const haloOptions = style.labelHalo ? { size: 2, color: 'white' } : { size: 0 };
    const labelSymbol = new TextSymbol3DLayer({
        material: {
            color: '#333333',
        },
        font: {
            size: style.labelSize,
            family: 'Arial',
        },
        text: title,
        horizontalAlignment: 'center',
        verticalAlignment: 'baseline',
        halo: haloOptions,
    });
    const pointSymbol = new PointSymbol3D({
        symbolLayers: [labelSymbol],
        verticalOffset: {
            screenLength: 20,
        },
        callout: {
            type: 'line',
            color: 'white',
        },
    });
    labelPoint.set('symbol', pointSymbol);
    labelPoint.set('key', key);

    return labelPoint;
};

export const handleLabelChange = (
    selectedGraphic: ExtendedGraphic,
    style: AnnotationStyleProperties,
    layer: GraphicsLayer
) => {
    const selectedGraphicKey =
        get(selectedGraphic, 'key')?.toString() ?? selectedGraphic.getAttribute?.('key');
    const labelKey = `${selectedGraphicKey}--label`;
    layer.graphics
        .filter((item) => get(item, 'key') == labelKey)
        .forEach((graphic) => {
            layer.remove(graphic);
        });
    if (style.labelingEnabled) {
        const label = createLabelPoint(selectedGraphic, labelKey, style);
        layer.graphics.add(label);
    }
};
