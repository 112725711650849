import type OktaAuth from '@okta/okta-auth-js';

import { getClientUserToken } from 'utils/apiClient/shareLinkSessionStorage';
import config from 'utils/config';
import clientAllowedEndpoints from './2023-02-02-endpoints.json';
import { ApiRequestParameters, mergeHeaderObjects } from './apiClientUtils';
import ApiEndpointTemplate from './ApiEndpointTemplate';
import { EndpointAllowlistEntry } from './urlUtil';

interface ErrorResult {
    status: number;
    message: string;
}

export class ApiEndpointError extends Error {
    statusCode: number;
    errorResponse: unknown;

    constructor(
        message: string,
        statusCode: number,
        errorResponse: unknown,
        options?: ErrorOptions
    ) {
        super(message, options);
        this.name = 'ApiEndpointError';
        this.statusCode = statusCode;
        this.errorResponse = errorResponse;
    }
}

const preFetchHooks: PreFetchHook[] = [];
const onUnauthentaticatedHooks: Array<() => void> = [];

export type PreFetchHook = (
    url: string,
    method: string,
    options: RequestInit
) => [url: string, method: string, options: RequestInit] | void;

export function addPreFetchHook(hook: PreFetchHook): () => void {
    preFetchHooks.push(hook);

    return () => preFetchHooks.remove(hook);
}

export function addOnUnauthenticatedHook(hook: () => void): () => void {
    onUnauthentaticatedHooks.push(hook);

    return () => onUnauthentaticatedHooks.remove(hook);
}

export function createRestrictToClientAccessibleHook(): (url: string, method: string) => void {
    const allowListEntries = clientAllowedEndpoints.map(
        (entry) => new EndpointAllowlistEntry(entry)
    );

    return (url: string, method: string) => {
        if (!url.startsWith(config.blackbirdApiUrl)) {
            return;
        }
        let urlPath = '/' + url.replace(config.blackbirdApiUrl, '');
        const queryPathIndex = urlPath.indexOf('?');
        if (queryPathIndex >= 0) {
            urlPath = urlPath.slice(0, queryPathIndex);
        }

        if (!allowListEntries.some((endpoint) => endpoint.test(urlPath, method))) {
            throw new Error(`${urlPath} failed client allowlist test`);
        }
    };
}

let authClient: OktaAuth | undefined;
export function setOktaAuthInstance(newAuthClient: OktaAuth): void {
    authClient = newAuthClient;
}

interface DoFetchParameters {
    url: string;
    method: string;
    options?: RequestInit;
    isRetry?: boolean;
    useAuth?: boolean;
}
export async function doFetch({
    url,
    method,
    options = {},
    isRetry = false,
    useAuth = true,
}: DoFetchParameters): Promise<Response> {
    preFetchHooks.forEach((hook) => {
        const result = hook(url, method, options);
        if (Array.isArray(result)) {
            [url, method, options] = result;
        }
    });

    const headers = new Headers(options?.headers);
    const authorizationHeaders = createAuthorizationHeader();
    if (useAuth) {
        headers.append('Authorization', authorizationHeaders.Authorization);
    }
    headers.append('Subscription-Key', authorizationHeaders['Subscription-Key']);

    const response = await fetch(url, {
        method,
        ...options,
        headers,
    });

    if (!response.ok) {
        if (!isRetry && (await wasExpiredCheck(response))) {
            return await doFetch({ url, method, options, useAuth, isRetry: true });
        }

        const error = await parseErrorResult(response);

        const message = `Error ${response.status}: ${error.message}`;
        throw new ApiEndpointError(message, response.status, error);
    }

    return response;
}

async function parseErrorResult(response: Response): Promise<ErrorResult> {
    try {
        return await response.json();
    } catch (error) {
        return {
            status: response.status,
            message: response.statusText,
        };
    }
}

let sessionExpiredCheckPromise: Promise<boolean> | undefined = undefined;
async function wasExpiredCheck(response: Response): Promise<boolean> {
    async function forceRecheckAuth() {
        // Check whether the user is authenticated without trying to renew the tokens
        const wasAuthenticated =
            (await authClient?.isAuthenticated({ onExpiredToken: 'none' })) ?? false;
        // Check whether the user is authenticated with an attempt to renew the token first
        let isAuthenticated = false;
        try {
            isAuthenticated =
                (await authClient?.isAuthenticated({ onExpiredToken: 'renew' })) ?? false;
        } catch (e) {
            console.error(e);
        }

        // If the user is no longer authenticated show an error
        if (!isAuthenticated) {
            onUnauthentaticatedHooks.forEach((hook) => {
                try {
                    hook();
                } catch (e) {
                    console.error(e);
                }
            });
        }

        // Finished checking/refreshing session, so allow new checks to occur
        sessionExpiredCheckPromise = undefined;

        // If the user was previously unauthenticated, and now is, we should retry the request
        return !wasAuthenticated && isAuthenticated;
    }

    const clientToken = getClientUserToken();
    // We have a separate check implemented for client share users
    if (clientToken) {
        return false;
    }

    if (response.status !== 401) {
        return false;
    }

    // Reuse promise so that only one check/refresh occurs at a time
    if (!sessionExpiredCheckPromise) {
        sessionExpiredCheckPromise = forceRecheckAuth();
    }

    return sessionExpiredCheckPromise;
}

function createAuthorizationHeader() {
    const authorizationType = 'Bearer';
    const subscriptionKey = config.blackbirdApiSubscriptionKey;
    return {
        Authorization: `${authorizationType} ${config.accessToken}`,
        'Subscription-Key': subscriptionKey,
    };
}

export default class ApiEndpoint<T extends string> {
    template: ApiEndpointTemplate<T>;
    constructor(template: string) {
        this.template = new ApiEndpointTemplate(template);
    }

    buildUrl({ templateValues, queryParameters }: ApiRequestParameters<T> = {}): string {
        return config.blackbirdApiUrl + this.template.build({ templateValues, queryParameters });
    }

    get({
        templateValues,
        queryParameters,
        fetchOptions,
        useAuth,
    }: ApiRequestParameters<T> = {}): Promise<Response> {
        const url = this.buildUrl({ templateValues, queryParameters });
        return doFetch({ url: url, method: 'get', options: fetchOptions, useAuth });
    }

    post({
        templateValues,
        queryParameters,
        fetchOptions,
        useAuth,
    }: ApiRequestParameters<T> = {}): Promise<Response> {
        const url = this.buildUrl({ templateValues, queryParameters });
        fetchOptions = insertContentTypeHeader(fetchOptions);
        return doFetch({ url: url, method: 'post', options: fetchOptions, useAuth });
    }

    put({
        templateValues,
        queryParameters,
        fetchOptions,
        useAuth,
    }: ApiRequestParameters<T> = {}): Promise<Response> {
        const url = this.buildUrl({ templateValues, queryParameters });
        fetchOptions = insertContentTypeHeader(fetchOptions);
        return doFetch({ url, method: 'put', options: fetchOptions, useAuth });
    }

    delete({
        templateValues,
        queryParameters,
        fetchOptions,
        useAuth,
    }: ApiRequestParameters<T> = {}): Promise<Response> {
        const url = this.buildUrl({ templateValues, queryParameters });
        return doFetch({ url, method: 'delete', options: fetchOptions, useAuth });
    }
}
function insertContentTypeHeader(fetchOptions: RequestInit | undefined) {
    if (!(fetchOptions && fetchOptions.body instanceof FormData)) {
        fetchOptions = {
            ...fetchOptions,
            headers: mergeHeaderObjects(
                { 'Content-Type': 'application/json' },
                fetchOptions && fetchOptions.headers != null ? fetchOptions.headers : undefined
            ),
        };
    }
    return fetchOptions;
}
